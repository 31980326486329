import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from '@emotion/styled';

import 'swiper/css';
import 'swiper/css/autoplay';
import { HomeNewsMention } from '@pageSections/homePage/news/types';
import { useWindowWidth } from '@customHooks';
import { OUTER_SPACE } from '@constants';

type Props = {
  mentions: HomeNewsMention[];
};

const NewsCarousel: React.FC<Props> = ({ mentions }) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 770;

  return (
    <Swiper
      className='news-carousel'
      modules={[Autoplay]}
      autoHeight={false}
      autoplay={!isDesktop ? { delay: 3000, disableOnInteraction: false } : {}}
      breakpoints={{
        1000: {
          slidesPerView: 4,
        },
        770: {
          slidesPerView: 3,
        },
        620: {
          slidesPerView: 2,
        },
        375: {
          slidesPerView: 1.3,
        },
        320: {
          slidesPerView: 1.1,
        },
      }}
    >
      {mentions.map((item, index) => {
        return (
          <SwiperSlide key={index}>
            <MentionWrapper>
              <img height={40} src={item.logo.url} alt={item.logo.alt} />
              <Mention>{item.mention}</Mention>
              <PostLink href={item.post_link} target='_blank' rel='noreferrer'>
                Read more
              </PostLink>
            </MentionWrapper>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default NewsCarousel;

const PostLink = styled.a`
  color: ${OUTER_SPACE};
  font-size: 16px;
  line-height: 20.8px;
  margin-top: 2px;
`;

const MentionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 230px;
  img {
    object-fit: contain;
    margin-right: auto;
  }
`;

const Mention = styled.div`
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 400;
  width: 240px;
  margin-top: 16px;
`;
