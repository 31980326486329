import React from 'react';
import styled from '@emotion/styled';
import { HeaderSubtitle, HeaderTitle, HeaderTitleWrapper, SectionWrapper, theme } from '@styles';
import { HomeAboutUsSectionData } from './types';

const { breakpoints } = theme;

const AboutUsSection: React.FC<HomeAboutUsSectionData> = (props) => {
  const {
    about_us_title,
    about_us_subtitle,
    about_us_image,
    about_us_inner_description,
    about_us_inner_title,
    about_us_investors,
    about_us_investors_label,
  } = props;

  return (
    <SectionWrapper>
      <HeaderTitleWrapper>
        <HeaderTitle>{about_us_title}</HeaderTitle>
        <HeaderSubtitle>{about_us_subtitle}</HeaderSubtitle>
      </HeaderTitleWrapper>
      <Padding>
        <ImageWrapper>
          <img src={about_us_image.url} alt={about_us_image.alt} />
          <div>{about_us_image.alt}</div>
        </ImageWrapper>
        <BodyWrapper>
          <Title>{about_us_inner_title}</Title>
          <Body>
            <Description dangerouslySetInnerHTML={{ __html: about_us_inner_description.html }} />
            <InvestorsLabel>{about_us_investors_label}</InvestorsLabel>
            <InvestorLogos>
              {about_us_investors.map((investor, index) => {
                return (
                  <a href={investor.link} key={index} rel='noreferrer' target='_blank'>
                    <img src={investor.logo.url} alt={investor.logo.alt} width={110} />
                  </a>
                );
              })}
            </InvestorLogos>
          </Body>
        </BodyWrapper>
      </Padding>
    </SectionWrapper>
  );
};

export default AboutUsSection;

const Padding = styled.div`
  padding: 0 24px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const Body = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: 50%;
  }
`;

const InvestorLogos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  align-items: center;
  img {
    object-fit: contain;
  }
  a {
    &:not(:last-child) {
      margin-right: 22px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  margin-top: 30px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    a {
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
`;

const InvestorsLabel = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  max-width: 276px;
  text-align: left;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: initial;
  }
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
  p {
    margin: 0px;
    margin-bottom: 24px;
  }
  br {
    display: none;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.1px;
    max-width: 436px;
  }
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  margin-bottom: 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.1px;
    max-width: 450px;
    margin-right: 60px;
  }
`;

const ImageWrapper = styled.div`
  height: 300px;
  position: relative;
  margin-bottom: 32px;
  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  div {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 16px;
    color: #fff;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 18.2px;
    font-weight: 700;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: flex;
    justify-content: center;
    width: 1000px;
    height: 448px;
    margin: 0 auto 70px auto;
    img {
      border-radius: 30px;
      width: 100%;
      height: 448px;
      object-fit: initial;
    }
    div {
      width: initial;
      text-align: left;
      position: absolute;
      left: 32px;
      bottom: 25px;
      font-size: 16px;
      line-height: 20.8px;
      font-weight: 700;
    }
  }
`;
