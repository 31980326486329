import styled from '@emotion/styled';
import { HomeAboutUsSectionData } from '@pageSections/homePage/about-us/types';
import React from 'react';
import { theme } from '@styles';
const { breakpoints } = theme;

const Achievements: React.FC<HomeAboutUsSectionData> = ({ achievement_link_text, achievements }) => {
  return (
    <Wrapper>
      {achievements.map((achievement, index) => {
        return (
          <Achievement key={index}>
            <AchievementImage src={achievement.achievement_image.url} alt={achievement.achievement_image.alt} />
            <AchievementText>
              <AchievementTitle>{achievement.achievement_title}</AchievementTitle>
              <a href={achievement.achievement_link} target='_blank' rel='noreferrer'>
                {achievement_link_text}
              </a>
            </AchievementText>
          </Achievement>
        );
      })}
    </Wrapper>
  );
};

export default Achievements;

const AchievementText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 300px;
  }
`;

const AchievementTitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  font-family: 'Poppins';
  font-weight: 700;
`;

const AchievementImage = styled.img`
  width: 80px;
  object-fit: contain;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: 100px;
  }
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    padding: 0px;
    flex-direction: row;
  }
`;

const Achievement = styled.div`
  display: flex;
  align-items: center;
  a {
    color: #2f444e;
    font-size: 16px;
    line-height: 20.8px;
    margin-top: 8px;
    width: 100px;
    &:hover {
      opacity: 0.7;
    }
  }
  &:first-child {
    margin-bottom: 32px;
    @media screen and (min-width: ${breakpoints.smallLaptop}) {
      margin-right: 88px;
      margin-bottom: 0px;
    }
  }
`;
