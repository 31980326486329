import React from 'react';
import styled from '@emotion/styled';
import { SectionSubtitle, SectionTitle, SectionWrapper } from '@styles';
import { HomeNewsSectionData } from './types';
import NewsCarousel from '@components/Carousels/NewsCarousel';

const NewsSection: React.FC<HomeNewsSectionData> = (props) => {
  const { news_title, news_subtitle, news_mentions } = props;

  return (
    <SectionWrapper>
      <SectionTitle>{news_title}</SectionTitle>
      <SectionSubtitle>{news_subtitle}</SectionSubtitle>
      <NewsCarouselWrapper>
        <NewsCarousel mentions={news_mentions} />
      </NewsCarouselWrapper>
    </SectionWrapper>
  );
};

export default NewsSection;

const NewsCarouselWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;
