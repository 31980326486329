import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { PageContext, IPrismicAboutUs } from '@appTypes';

import AboutUsSection from '@pageSections/homePage/about-us';
import NewsSection from '@pageSections/homePage/news';
import FooterSection from '@pageSections/common/footer';
import Achievements from '@pageSections/aboutUsPage/achievements';
import Separator from '@components/Separator';
import CallToActionSection from '@pageSections/common/callToAction';

const AboutUsPage: React.FC<PageProps<IPrismicAboutUs, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url } = data;

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        image={meta_image.url}
        url={meta_url}
        pathname={location.pathname}
        name='Hyperhuman'
        locale={locale}
      />
      <Container>
        <AboutUsSection {...data} />
        <Separator />
        <Achievements {...data} />
        <Separator />
        <NewsSection {...data} />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default AboutUsPage;

const Container = styled.div`
  position: initial;
`;

export const pageQuery = graphql`
  query AboutUsQuery($locale: String!) {
    prismicData: allPrismicAboutUs(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_keywords
            meta_description
            meta_image {
              url
            }
            meta_url
            news_title
            news_subtitle
            news_mentions {
              logo {
                url
                alt
              }
              mention
              post_link
            }
            about_us_title
            about_us_subtitle
            about_us_image {
              url
              alt
            }
            about_us_inner_title
            about_us_inner_description {
              raw
              text
              html
            }
            about_us_investors_label
            about_us_investors {
              logo {
                url
                alt
              }
              link
            }
            achievement_link_text
            achievements {
              achievement_image {
                url
                alt
              }
              achievement_title
              achievement_link
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
